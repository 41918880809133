












import HeaderShortcutsItem from '@/components/Header/Shortcuts/ShortcutItem.vue';
import iconJson from './Icons.json';
import { onMounted, ref } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  iconFile: {
    type: Object,
    default: iconJson
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const items = ref([]);
  const handleLaunchSearch = () => {
    emit('launchSearch');
  };
  const props = __props;
  const loadIconsFile = async () => {
    items.value = Object.values(props.iconFile).map(({
      name,
      iconName,
      function: func,
      badge,
      alwaysShow,
      authIcon
    }) => ({
      name,
      iconName,
      function: func,
      badge,
      alwaysShow,
      authIcon
    }));
  };
  onMounted(async () => {
    await loadIconsFile();
  });
  return {
    items,
    handleLaunchSearch
  };
};
__sfc_main.components = Object.assign({
  HeaderShortcutsItem
}, __sfc_main.components);
export default __sfc_main;

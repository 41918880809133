





















import { SfButton, SfCharacteristic } from '@storefront-ui/vue';
import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';

export default defineComponent({
  name: 'StoreSwitcher',
  components: {
    SfButton,
    SfCharacteristic,


  },
  setup() {
    const { config } = useConfig();
    const route = useRoute();
    const storeLanguage =route.value.path.substring(0, 3) === '/en' ? '/en' : '/fr'

    const switchLanguage = () => {
      switch (storeLanguage) {
        case '/en':
          config.value.locale = 'fr_FR';
          config.value.header_logo_src = 'stores/1/Promulias-Logo-Noir.png';
          config.value.store_code = 'fr';
          config.value.store_name = 'Français_BToC';
          window.location.href = '/fr';
          break;
        case '/fr':
          config.value.locale = 'en_GB';
          config.value.header_logo_src = 'websites/1/Promulias-Logo-Noir.png';
          config.value.store_code = 'en';
          config.value.store_name = 'English_BToC';
          window.location.href = '/en';
          break;
      }
    };
    return {
      storeConfig: config,
      storeLanguage,
      changeLanguage: switchLanguage
    };
  }
});

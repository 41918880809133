








































































































import { SfLink, SfList, SfDivider } from '@storefront-ui/vue';
import { PropType, ref, onMounted } from '@nuxtjs/composition-api';
import { CategoryTree } from '~/modules/GraphQL/types';
import { useUiHelpers } from '~/composables';
const __sfc_main = {};
__sfc_main.props = {
  currentCategory: {
    type: (Object as PropType<CategoryTree | null>),
    default: () => null
  },
  hasFocus: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    getCatLink
  } = useUiHelpers();
  const hasChildren = (category: CategoryTree) => Boolean(category?.children.length > 0);
  const lvl1CatRefs = ref<string>([]);
  const lvl2CatRefs = ref<string>([]);
  const lvl2GroupedCatRefs = ref<string>([]);
  const getAllProductPath = (style: String) => {
    return style === "Robes" ? '/c/robes.html' : '/c/accessoires.html';
  };
  const getGroupedLvl2CatRefs = () => {
    let current = 0;
    const result = [];
    lvl1CatRefs.value.forEach(lvl1CatRef => {
      const groupCount = Number(lvl1CatRef.$attrs['data-children']);
      result.push(lvl2CatRefs.value.slice(current, current + groupCount));
      current += groupCount;
    });
    return result;
  };

  // Once submenu is opened we start from the very first element
  let lvl1CatFocusIdx = 0;
  // We start from the "outside" of lvl2Cats, navigation action must be performed to jump into lvl2CatTree
  let lvl2CatFocusIdx = -1;
  const navRight = () => {
    if (lvl1CatRefs.value[++lvl1CatFocusIdx]) {
      lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
      lvl2CatFocusIdx = -1;
      lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
    } else {
      lvl1CatFocusIdx--;
    }
  };
  const navLeft = () => {
    if (lvl1CatRefs.value[--lvl1CatFocusIdx]) {
      lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
      lvl2CatFocusIdx = -1;
      lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
    } else {
      lvl1CatFocusIdx++;
    }
  };
  const navDown = () => {
    lvl2CatFocusIdx++;
    if (lvl2CatFocusIdx !== -1 && !lvl2GroupedCatRefs.value[lvl1CatFocusIdx][lvl2CatFocusIdx]) {
      lvl2CatFocusIdx--;
      return;
    }
    lvl2GroupedCatRefs.value[lvl1CatFocusIdx][lvl2CatFocusIdx].$el.focus();
  };
  const navUp = () => {
    if (lvl2CatFocusIdx > 0) {
      lvl2CatFocusIdx--;
      lvl2GroupedCatRefs.value[lvl1CatFocusIdx][lvl2CatFocusIdx].$el.focus();
      return;
    }
    if (lvl2CatFocusIdx === 0) {
      lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
      lvl2CatFocusIdx = -1;
      return;
    }
    if (lvl2CatFocusIdx === -1) {
      emit('hideSubcategories');
    }
  };
  const setupNav = () => {
    lvl2CatFocusIdx = -1;
    lvl1CatRefs.value[lvl1CatFocusIdx].$el.focus();
    lvl2GroupedCatRefs.value = getGroupedLvl2CatRefs();
  };
  onMounted(() => {
    if (props.hasFocus) {
      setupNav();
    }
  });
  return {
    getCatLink,
    hasChildren,
    lvl1CatRefs,
    lvl2CatRefs,
    getAllProductPath,
    navRight,
    navLeft,
    navDown,
    navUp,
    setupNav
  };
};
__sfc_main.components = Object.assign({
  SfDivider,
  SfLink,
  SfList
}, __sfc_main.components);
export default __sfc_main;

























import { useRouter, useContext, computed, ref, onMounted } from '@nuxtjs/composition-api';
import { SfButton, SfBadge } from '@storefront-ui/vue';
import { useUiState } from '@/composables';
import { useWishlistStore } from '@/modules/wishlist/store/wishlistStore';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
const __sfc_main = {};
__sfc_main.props = {
  item: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const router = useRouter();
  const {
    app
  } = useContext();
  const {
    toggleLoginModal
  } = useUiState();
  const {
    isAuthenticated
  } = useUser();
  const wishlistStore = useWishlistStore();
  const {
    loadItemsCount: loadWishlistItemsCount
  } = useWishlist();
  const {
    loadTotalQty: loadCartTotalQty,
    cart
  } = useCart();
  const handleClick = () => {
    eval(props.item.function + '()');
  };
  const handleContactClick = () => {
    router.push(app.localeRoute({
      name: 'contact-us'
    }));
  };
  const handleSearchClick = () => {
    emit('launchSearch');
  };
  const handleMapClick = () => {
    router.push(app.localeRoute({
      name: 'store-locator'
    }));
  };
  const handleWishlistClick = () => {
    isAuthenticated.value === true ? router.push(app.localeRoute({
      name: 'wishlist'
    })) : handleAccountClick();
  };
  const handleAccountClick = async () => {
    if (isAuthenticated.value === true) {
      router.push(app.localeRoute({
        name: 'customer-my-profile'
      }));
    } else {
      await loadWishlistItemsCount({});
      toggleLoginModal();
    }
  };
  const handleCartClick = async () => {
    await router.push(app.localeRoute({
      name: 'cart'
    }));
  };
  if (props.item.name == 'Wishlist') {
    props.item.badgeValue = computed(() => wishlistStore.wishlist?.items_count ?? 0);
  }
  if (props.item.name == 'Cart') {
    props.item.badgeValue = computed(() => cart.value?.total_quantity ?? 0);
  }
  onMounted(async () => {
    if (app.$device.isDesktop) {
      await loadCartTotalQty();
      await loadWishlistItemsCount({});
    }
  });
  return {
    isAuthenticated,
    handleClick
  };
};
__sfc_main.components = Object.assign({
  SfButton,
  SfBadge
}, __sfc_main.components);
export default __sfc_main;










































































































import { SfSidebar, SfList, SfMenuItem } from '@storefront-ui/vue';
import { defineComponent, useRouter, useContext, computed, ref, useRoute, onMounted } from '@nuxtjs/composition-api';
import { useConfig, useUiHelpers, useUiState } from '~/composables';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { CategoryTree } from '~/modules/GraphQL/types';
import { useMobileCategoryTree } from './logic';
import StoreSwitcher from '@/components/StoreSwitcher.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    isMobileMenuOpen,
    toggleMobileMenu
  } = useUiState();
  const {
    getCatLink
  } = useUiHelpers();
  const router = useRouter();
  const app = useContext();
  const showSubcategories = ref(false);
  const {
    categoryAncestors: initialHistory,
    categoryTree
  } = useTraverseCategory();
  const navigate = (category: CategoryTree) => {
    if (category.name == 'Robes' || category.name == 'Dress') {
      toggleSubcategories();
    } else {
      toggleMobileMenu();
      const path = getCatLink(category) != '/c/undefined' ? app.localePath((getCatLink(category) as string)) : app.localePath('/c/robes.html');
      router.push(path);
    }
  };
  const navigateTo = category => {
    toggleMobileMenu();
    const path = category == 'long' ? app.localePath('/c/robes/robes-longues.html') : app.localePath('/c/robes/robes-courtes.html');
    router.push(path);
  };
  const returnCategoryUp = () => {
    onGoCategoryUp();
    showSubcategories.value = false;
  };
  const toggleSubcategories = () => {
    showSubcategories.value = !showSubcategories.value;
    onGoCategoryDown({});
  };

  // A category with no child categories can't be entered into - it can only navigated to
  const initialHistoryWithSnippedSubcategoryLessTail = initialHistory.value.at(-1)?.children?.length ? initialHistory.value : initialHistory.value.slice(0, -1);
  const {
    current: currentCategory,
    history,
    currentItems,
    onGoCategoryUp,
    onGoCategoryDown
  } = useMobileCategoryTree(initialHistoryWithSnippedSubcategoryLessTail);
  const itemsIncludedInMenu = computed(() => {
    const topLevelItems = categoryTree.value.children;
    const maybeCurrentCategoryItems = currentItems.value || topLevelItems;
    return maybeCurrentCategoryItems.filter(item => item.include_in_menu);
  });
  const navigatePage = async page => {
    toggleMobileMenu();
    await router.push(`${app.localePath(page)}`);
  };
  return {
    toggleMobileMenu,
    showSubcategories,
    navigate,
    navigateTo,
    returnCategoryUp,
    currentCategory,
    onGoCategoryUp,
    onGoCategoryDown,
    itemsIncludedInMenu,
    navigatePage
  };
};
__sfc_main.components = Object.assign({
  SfSidebar,
  SfList,
  SfMenuItem,
  StoreSwitcher
}, __sfc_main.components);
export default __sfc_main;

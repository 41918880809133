










const __sfc_main = {};
__sfc_main.props = {
  dataCy: {
    type: String,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
